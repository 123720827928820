<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3511_15981)">
      <path
        d="M24.0002 16.0002L21.6135 16.0002C21.0135 16.0002 20.7202 16.7202 21.1468 17.1335L24.8668 20.8535C25.1335 21.1202 25.5468 21.1202 25.8135 20.8535L29.5335 17.1335C29.9468 16.7202 29.6535 16.0002 29.0535 16.0002L26.6668 16.0002C26.6668 10.1068 21.8935 5.3335 16.0002 5.3335C14.6135 5.3335 13.2802 5.60016 12.0668 6.0935C11.1735 6.4535 10.9335 7.60016 11.6135 8.28016C11.9735 8.64016 12.5202 8.78683 13.0002 8.58683C13.9202 8.20016 14.9468 8.00016 16.0002 8.00016C20.4135 8.00016 24.0002 11.5868 24.0002 16.0002ZM20.3868 23.7202C20.0268 23.3602 19.4668 23.2135 19.0002 23.4135C18.0668 23.7868 17.0535 24.0002 16.0002 24.0002C11.5868 24.0002 8.00016 20.4135 8.00016 16.0002L10.3868 16.0002C10.9868 16.0002 11.2802 15.2802 10.8535 14.8668L7.1335 11.1468C6.86683 10.8802 6.45349 10.8802 6.18683 11.1468L2.46683 14.8668C2.05349 15.2802 2.34683 16.0002 2.9335 16.0002L5.33349 16.0002C5.33349 21.8935 10.1068 26.6668 16.0002 26.6668C17.3868 26.6668 18.7202 26.4002 19.9335 25.9068C20.8268 25.5468 21.0668 24.4002 20.3868 23.7202Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3511_15981">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconAutoRenew',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
