<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7143 8.78571H8.78571V12.7143C8.78571 13.1464 8.43214 13.5 8 13.5C7.56786 13.5 7.21429 13.1464 7.21429 12.7143V8.78571H3.28571C2.85357 8.78571 2.5 8.43214 2.5 8C2.5 7.56786 2.85357 7.21429 3.28571 7.21429H7.21429V3.28571C7.21429 2.85357 7.56786 2.5 8 2.5C8.43214 2.5 8.78571 2.85357 8.78571 3.28571V7.21429H12.7143C13.1464 7.21429 13.5 7.56786 13.5 8C13.5 8.43214 13.1464 8.78571 12.7143 8.78571Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconPlus',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
}
</script>
