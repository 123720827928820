<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3768_2959)">
      <path
        d="M10 5.28068C12.7161 5.28068 15.1542 6.77364 16.4679 9.16946C16.6112 9.43082 16.6122 9.74701 16.4693 10.0086C16.1757 10.546 15.8226 11.0362 15.4222 11.4746C15.1297 11.7948 15.1075 12.2926 15.409 12.6043C15.6958 12.9009 16.1693 12.9174 16.4507 12.6155C17.1724 11.8412 17.7649 10.9402 18.1913 9.9414C18.2875 9.71617 18.2872 9.46142 18.1908 9.2363C16.7987 5.98484 13.6571 3.7141 10 3.7141C9.50146 3.7141 9.01306 3.75617 8.53695 3.83703C7.92685 3.94065 7.74077 4.67582 8.17099 5.12064C8.37115 5.32759 8.66281 5.41451 8.94716 5.3694C9.29251 5.31461 9.64262 5.28068 10 5.28068ZM10 6.06397C9.63234 6.06397 9.50411 6.49901 9.75973 6.7633L10.7576 7.79504C11.1894 7.99086 11.5379 8.35118 11.7273 8.79765L12.7069 9.81053C12.96 10.0722 13.4015 9.94503 13.4015 9.58094C13.4091 7.63838 11.8788 6.06397 10 6.06397ZM2.94615 3.07673C2.65908 3.37565 2.65992 3.84809 2.94804 4.14599L4.46214 5.71149C3.32227 6.63699 2.40187 7.84281 1.80821 9.23654C1.71238 9.46152 1.71278 9.71591 1.80902 9.94072C3.20096 13.1925 6.34271 15.4634 10 15.4634C11.1515 15.4634 12.2576 15.2363 13.2727 14.8211L15.3117 16.9293C15.6135 17.2414 16.1138 17.2414 16.4156 16.9293C16.7035 16.6317 16.7036 16.1594 16.4158 15.8617L4.05576 3.07493C3.75223 2.76092 3.24865 2.76174 2.94615 3.07673ZM8.11366 9.48695L10.0909 11.5313C10.0606 11.5392 10.0303 11.547 10 11.547C8.95457 11.547 8.10608 10.6697 8.10608 9.58877C8.10608 9.54961 8.11366 9.52611 8.11366 9.48695ZM5.5379 6.82376L6.86366 8.19452C6.68941 8.62533 6.59093 9.0953 6.59093 9.58877C6.59093 11.5313 8.12123 13.1136 10 13.1136C10.4773 13.1136 10.9318 13.0118 11.3409 12.8316L12.0834 13.5992C11.4167 13.7872 10.7197 13.8969 10 13.8969C7.28491 13.8969 4.84755 12.4049 3.53354 10.0106C3.38935 9.74784 3.38913 9.42975 3.53404 9.1674C4.04912 8.23489 4.73882 7.45195 5.5379 6.82376Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3768_2959">
        <rect :width="size" :height="size" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconEyeOff',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
