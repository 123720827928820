<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 6.4C15.6177 6.4 18.8441 8.4235 20.4191 11.625C18.8441 14.8265 15.6177 16.85 12 16.85C8.38227 16.85 5.15591 14.8265 3.58091 11.625C5.15591 8.4235 8.38227 6.4 12 6.4ZM12 4.5C7.22727 4.5 3.15136 7.4545 1.5 11.625C3.15136 15.7955 7.22727 18.75 12 18.75C16.7727 18.75 20.8486 15.7955 22.5 11.625C20.8486 7.4545 16.7727 4.5 12 4.5ZM12 9.25C13.3173 9.25 14.3864 10.314 14.3864 11.625C14.3864 12.936 13.3173 14 12 14C10.6827 14 9.61364 12.936 9.61364 11.625C9.61364 10.314 10.6827 9.25 12 9.25ZM12 7.35C9.63273 7.35 7.70455 9.269 7.70455 11.625C7.70455 13.981 9.63273 15.9 12 15.9C14.3673 15.9 16.2955 13.981 16.2955 11.625C16.2955 9.269 14.3673 7.35 12 7.35Z"
      fill="white"
    />
    <path
      d="M12 6.4C15.6177 6.4 18.8441 8.4235 20.4191 11.625C18.8441 14.8265 15.6177 16.85 12 16.85C8.38227 16.85 5.15591 14.8265 3.58091 11.625C5.15591 8.4235 8.38227 6.4 12 6.4ZM12 4.5C7.22727 4.5 3.15136 7.4545 1.5 11.625C3.15136 15.7955 7.22727 18.75 12 18.75C16.7727 18.75 20.8486 15.7955 22.5 11.625C20.8486 7.4545 16.7727 4.5 12 4.5ZM12 9.25C13.3173 9.25 14.3864 10.314 14.3864 11.625C14.3864 12.936 13.3173 14 12 14C10.6827 14 9.61364 12.936 9.61364 11.625C9.61364 10.314 10.6827 9.25 12 9.25ZM12 7.35C9.63273 7.35 7.70455 9.269 7.70455 11.625C7.70455 13.981 9.63273 15.9 12 15.9C14.3673 15.9 16.2955 13.981 16.2955 11.625C16.2955 9.269 14.3673 7.35 12 7.35Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconEyeOn',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
