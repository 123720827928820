// 3600(access 만료)보다는 빠르게 silent refresh 해야 access 토큰 만료 대응을 안 할 수 있음
// silent refresh 사용하지 않고 api 실패시 자동 refresh 로직으로 개선 여지 있음
// refresh 요구가 빈번하여 서버에 부담을 줄 때 refactoring 할 것
const ACCESS_EXPIRE_TIME = 1000 * 3000

export default {
  namespaced: true,
  state: {
    loginId: '',
    accessToken: null,
    refreshToken: null,
  },
  getters: {
    loginId: (state) => {
      return state.loginId
    },
  },
  mutations: {
    setLoginId (state, loginId) {
      state.loginId = loginId
    },
    setAccessToken (state, token={}) {
      state.accessToken = token.accessToken || null
      state.refreshToken = token.refreshToken || null
    },
  },
  actions: {
    async loginSparkOne ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}login`,
          payload,
        )
        await dispatch('onLoginSuccess', response)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async logoutSparkOne ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}logout`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 로그인 성공 처리 (토큰 저장 / 자동 갱신 등록)
    async onLoginSuccess ({ rootState, commit, dispatch }, payload) {
      // const response = null
      try {
        commit('setAccessToken', payload.data)
        // Silent Refresh
        setTimeout(() => {
          dispatch('refresh')
        }, ACCESS_EXPIRE_TIME)
      } catch (error) {
        return false
      }
      return true
    },
    // 토큰 갱신
    async refresh ({ dispatch }) {
      try {
        const response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}refresh`,
        )
        await dispatch('onLoginSuccess', response)
        return true
      } catch (error) {
        return false
      }
    },
  },
}
