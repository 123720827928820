<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3672_38561)">
      <path
        d="M16.003 13.3534L25.0742 22.1801C25.3963 22.4923 25.8328 22.6674 26.2876 22.6667C26.7424 22.6661 27.1784 22.4898 27.4995 22.1766C27.8206 21.8635 28.0007 21.4391 28 20.9969C27.9993 20.5547 27.818 20.1308 27.4959 19.8186L17.222 9.81852C16.9101 9.51638 16.4903 9.34276 16.0501 9.33374C15.6099 9.32473 15.183 9.48102 14.8582 9.77013L4.50406 19.8117C4.18199 20.1239 4.00067 20.5477 4 20.99C3.99933 21.4322 4.17937 21.8565 4.50051 22.1697C4.82165 22.4829 5.25758 22.6591 5.7124 22.6598C6.16722 22.6604 6.60368 22.4854 6.92576 22.1732L16.003 13.3534Z"
        fill="white"
      />
      <path
        d="M16.003 13.3534L25.0742 22.1801C25.3963 22.4923 25.8328 22.6674 26.2876 22.6667C26.7424 22.6661 27.1784 22.4898 27.4995 22.1766C27.8206 21.8635 28.0007 21.4391 28 20.9969C27.9993 20.5547 27.818 20.1308 27.4959 19.8186L17.222 9.81852C16.9101 9.51638 16.4903 9.34276 16.0501 9.33374C15.6099 9.32473 15.183 9.48102 14.8582 9.77013L4.50406 19.8117C4.18199 20.1239 4.00067 20.5477 4 20.99C3.99933 21.4322 4.17937 21.8565 4.50051 22.1697C4.82165 22.4829 5.25758 22.6591 5.7124 22.6598C6.16722 22.6604 6.60368 22.4854 6.92576 22.1732L16.003 13.3534Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3672_38561">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconArrowUp',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
