export default {
  namespaced: true,
  state: {
    accessToken: null,
    passwordResetKey: null,
  },
  getters: {},
  mutations: {
    setPasswordResetKey (state, data) {
      state.passwordResetKey = data || null
    },
  },
  actions: {
    async recoveryIdByMobile ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}recovery/id/mobile`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async recoveryIdByMobile2fa ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}recovery/id/mobile/2fa/${payload.key}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async recoveryPasswordByMobile ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}recovery/password/mobile`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async recoveryPasswordByMobile2fa ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}recovery/password/mobile/2fa/${payload.key}`,
          payload,
        )
        commit('setPasswordResetKey', response.data.key)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async passwordReset ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}recovery/password/reset/${payload.key}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
