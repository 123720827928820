<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 10C16.66 10 17.2 10.54 17.2 11.2V16C17.2 16.66 16.66 17.2 16 17.2C15.34 17.2 14.8 16.66 14.8 16V11.2C14.8 10.54 15.34 10 16 10ZM15.988 4C9.364 4 4 9.376 4 16C4 22.624 9.364 28 15.988 28C22.624 28 28 22.624 28 16C28 9.376 22.624 4 15.988 4ZM16 25.6C10.696 25.6 6.4 21.304 6.4 16C6.4 10.696 10.696 6.4 16 6.4C21.304 6.4 25.6 10.696 25.6 16C25.6 21.304 21.304 25.6 16 25.6ZM17.2 22H14.8V19.6H17.2V22Z"
      fill="white"
    />
    <path
      d="M16 10C16.66 10 17.2 10.54 17.2 11.2V16C17.2 16.66 16.66 17.2 16 17.2C15.34 17.2 14.8 16.66 14.8 16V11.2C14.8 10.54 15.34 10 16 10ZM15.988 4C9.364 4 4 9.376 4 16C4 22.624 9.364 28 15.988 28C22.624 28 28 22.624 28 16C28 9.376 22.624 4 15.988 4ZM16 25.6C10.696 25.6 6.4 21.304 6.4 16C6.4 10.696 10.696 6.4 16 6.4C21.304 6.4 25.6 10.696 25.6 16C25.6 21.304 21.304 25.6 16 25.6ZM17.2 22H14.8V19.6H17.2V22Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconNotice',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
