import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

const Error404 = () => import('@/components/common/error/Error404')
const Error403 = () => import('@/components/common/error/Error403')
const Login = () => import('@/components/login/Login')
const RecoveryId = () => import('@/components/recovery/RecoveryId')
const RecoveryPassword = () => import('@/components/recovery/RecoveryPassword')
const RecoveryResetPassword = () => import('@/components/recovery/RecoveryResetPassword')
const TermsOfService = () => import('@/components/signup/TermsOfService')
const UserCreateForm = () => import('@/components/signup/UserCreateForm')
const Welcome = () => import('@/components/signup/Welcome')
const PassCertification = () => import('@/components/webview/PassCertification')
const TermsOfServiceApp = () => import('@/components/webview/TermsOfServiceApp')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
    },
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'Login' },
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
  },
  {
    path: '/signup',
    redirect: '/signup/step1',
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '회원가입',
    },
  },
  {
    path: '/signup/step1',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '회원가입',
    },
  },
  {
    path: '/signup/step2',
    name: 'UserCreateForm',
    component: UserCreateForm,
    beforeEnter: (to, from, next) => {
      const getCookie = (name) => {
        const matches = document.cookie.match(
          new RegExp(
            '(?:^|; )'
              + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')
              + '=([^;]*)',
          ),
        )
        return matches ? decodeURIComponent(matches[1]) : undefined
      }
      const impUid = getCookie('_imp_uid')
      if (impUid) {
        next()
      } else {
        next({
          name: 'TermsOfService',
          query: { redirect: to.query?.redirect },
        })
      }
    },
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '회원가입',
    },
  },
  {
    path: '/signup/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      navigation: false,
    },
  },
  {
    path: '/unregister',
    name: 'UnregisterForm',
    component: () => import('@/components/unregister/UnregisterForm.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      navigation: true,
      headerTitle: '회원탈퇴',
    },
  },
  {
    path: '/unregister-complete',
    name: 'UnregisterComplete',
    component: () => import('@/components/unregister/UnregisterComplete.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      navigation: false,
      headerTitle: '회원탈퇴 완료',
    },
  },
  {
    path: '/webview/pass-certification',
    name: 'PassCertification',
    component: PassCertification,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      headerTitle: '아이디/비밀번호 찾기',
    },
  },
  {
    path: '/webview/terms/privacy-policy',
    name: 'TermsOfServiceApp',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'oms-privacy-policy',
      headerTitle: '개인정보 처리방침',
    },
  },

  {
    path: '/webview/terms/privacy-policy-visitor',
    name: 'VisitorPrivacyPolicy',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'oms-privacy-policy-v',
      headerTitle: '방문객 관리를 위한 개인정보 수집 이용',
    },
  },

  {
    path: '/webview/terms/service-use',
    name: 'ServiceUse',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'oms-terms-of-service',
      headerTitle: '서비스 이용 약관',
    },
  },
  {
    path: '/webview/terms/location-services',
    name: 'LocationServices',
    component: TermsOfServiceApp,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'location-services',
    },
  },
  {
    path: '/webview/visit/qr',
    name: 'VisitInviteQr',
    component: () => import('@/components/webview/VisitInviteQr.vue'),
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'oms-privacy-policy-v',
      headerTitle: '방문객 QR',
    },
  },
  {
    path: '/community/reservation/invite/qr_agreement',
    name: 'VisitInviteAgree',
    component: () => import('@/components/webview/VisitInviteAgree.vue'),
    meta: {
      requiresAuth: false,
      authRequired: false,
      fullScreen: true,
      navigation: false,
      serviceType: 'building-plus-oms',
      termsType: 'oms-privacy-policy-v',
      headerTitle: '방문객 QR',
    },
  },
  {
    path: '/recovery/id',
    name: 'RecoveryId',
    component: RecoveryId,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '아이디/비밀번호 찾기',
    },
  },
  {
    path: '/recovery/password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '아이디/비밀번호 찾기',
    },
  },
  {
    path: '/recovery/reset-password',
    name: 'RecoveryResetPassword',
    component: RecoveryResetPassword,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: true,
      headerTitle: '비밀번호 설정',
    },
    props: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      navigation: false,
    },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('user/logoutSparkOne')
      } catch (error) {}
      window.location.replace(decodeURIComponent(to.query.redirect))
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}
// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      next()
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        // 페이지 접근 권한 확인 (구현 안 함)
        next()
        // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: window.location.href },
        })
      }
    }
    // 권한이 필요 없는 페이지
  } else {
    // 리다이렉트 파라미터 유무 확인
    let redirect = from.query?.redirect
    const allowed = /^h?t?t?p?s?:?\/\/[a-zA-Z0-9.-]{1,}\.sparkplus\.co(?:[/?][\w#!:.?+=&%@!-/]*)?$/
    // 허용된 도메인 여부
    if (!allowed.test(redirect)) {
      // 허용되지 않은 경우 강제 치환
      redirect = process.env.VUE_APP_DEFAULT_REDIRECTION
    }
    if (!allowed.test(to.query?.redirect)) {
      // 허용 안 될 시 치환 후 이동
      to.query.redirect = redirect
      next({ path: to.path, query: to.query })
    } else {
      // 허용 될 시 원뵨 rediect 물고 이동
      next()
    }
  }
})

export default router
