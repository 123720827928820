<template>
  <div id="spModalGroup">
    <sp-popup
      v-for="(modal, i) in modals"
      :key="i"
      :title="modal.title"
      :theme="modal.theme"
      :align="modal.align"
      :description="modal.message.trim()"
      :closeOnClickOutside="false"
    >
      <template #footer>
        <sp-button-group>
          <sp-button v-if="modal.actionCode === 'ALERT'" @click="onEmit(i, { emit: 'alert', value: undefined })">
            확인
          </sp-button>
          <sp-button
            v-if="modal.actionCode === 'LOGIN'"
            @click="
              () => {
                modals.splice(i)
                $router.push('/login').catch((e) => {})
              }
            "
          >확인
          </sp-button>
          <sp-button
            v-if="modal.actionCode === 'DISTRIBUTION_BATCH'"
            @click="
              () => {
                modals.splice(i)
                $router.push('/').catch((e) => {})
              }
            "
          >확인
          </sp-button>
          <template v-if="modal.actionCode === 'CONFIRM'">
            <sp-button @click="onEmit(i, { emit: 'confirm', value: false })" class="half-button" outline>{{ modal.cancelName }}</sp-button>
            <sp-button @click="onEmit(i, { emit: 'confirm', value: true })" class="half-button">{{ modal.confirmName }}</sp-button>
          </template>
        </sp-button-group>
      </template>
    </sp-popup>
  </div>
</template>
<script>
export default {
  name: 'SpModals',
  methods: {
    onEmit(i, emitKV) {
      this.modals.splice(i)
      this.$root.$emit(emitKV.emit, emitKV.value)
    },
  },
  computed: {
    modals() {
      return this.$store.state.modals
    },
  },
}
</script>
