export default {
  namespaced: true,
  state: {
    isLeave: false,
    isMembership: false,
    membershipList: [],
  },
  getters: {},
  mutations: {
    setLeave(state, data) {
      state.isLeave = data || false
    },
    setMembership(state, data) {
      state.isMembership = data || false
    },
    setMembershipList(state, data) {
      state.membershipList = data || []
    },
  },
  actions: {
    async getUserMembershipList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}member/membership`,
          payload,
        )
        commit('setMembershipList', response.data.membershipList)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteUserMembershipUnregister ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.APP_API_URL}member/membership-unregister`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteUserUnregister ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.MEMBER_API_URL}user`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
