<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9169 29.9525C15.0681 30.1849 13.2566 29.553 11.6986 28.3133C9.85537 26.8446 9.03907 24.7146 9.0223 22.5038C8.98316 17.4864 9.00553 12.4672 9.01484 7.44977C9.01671 6.06541 9.46213 4.80674 10.3977 3.72026C12.3304 1.47326 15.8621 1.41817 17.8488 3.61007C18.7881 4.64489 19.3378 5.84846 19.3434 7.20871C19.3565 9.90683 19.3565 12.605 19.3546 15.3013C19.3546 16.6495 19.3528 17.9995 19.3509 19.3477C19.3509 20.3842 19.2391 21.512 18.6371 22.4143C17.9513 23.4405 16.5852 23.945 15.3123 23.6815C14.1307 23.437 13.3274 22.5658 12.9249 21.5465C12.7646 21.1418 12.6677 20.7251 12.6677 20.293C12.6677 16.7856 12.664 13.2799 12.6714 9.77253C12.6714 9.51942 12.7087 9.25597 12.7963 9.01836C12.9733 8.54141 13.3554 8.2728 13.9089 8.27625C14.5034 8.28141 14.9041 8.58101 15.0458 9.10273C15.1352 9.42816 15.1278 9.78114 15.1296 10.1203C15.1352 13.3143 15.1296 16.5066 15.1389 19.7006C15.1389 20.3515 15.07 21.2055 16.0484 21.1849C16.9449 21.1659 16.8834 20.3429 16.8834 19.7179C16.8889 15.6974 16.8834 11.6769 16.8908 7.65811C16.8908 7.03825 16.7864 6.44593 16.4938 5.89495C15.5583 4.1335 13.5548 3.92861 12.2484 5.47482C11.6781 6.14978 11.5923 6.91083 11.5923 7.70804C11.5961 12.7255 11.5923 17.7446 11.5961 22.7621C11.5961 23.0462 11.6017 23.3337 11.6352 23.6161C11.816 25.1176 12.759 26.4365 14.3282 26.9582C15.7446 27.43 17.5468 27.3835 18.7955 26.5071C20.0684 25.6134 20.197 23.9174 20.3424 22.5658C20.443 21.6274 20.4262 20.6976 20.4262 19.7575C20.4262 19.7575 20.43 13.2042 20.4374 9.92921C20.4374 9.24392 20.5791 8.57757 21.3879 8.33307C21.8426 8.19532 22.18 8.29174 22.4539 8.50525C22.8136 8.78419 23 9.21293 23 9.64683V21.2985C23 24.4013 22.3589 27.5057 19.213 29.2035C18.4526 29.6133 17.6829 29.8578 16.9169 29.9542V29.9525Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconArrowPrev',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
