<template>
  <div class="spinner">
    <span class="sr-only">Now Loading</span>
  </div>
</template>
<script>
export default {
  name: 'IconSpinner',
}
</script>
<style lang="scss" scoped>
.spinner {
  position: relative;
  width: 76px;
  height: 76px;
  border: 10px solid $theme-gray-20;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  &:after {
    position: absolute;
    top: 46px;
    left: 47px;
    width: 10px;
    height: 10px;
    background: $theme-gray-20;
    border-radius: 50%;
    content: '';
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
