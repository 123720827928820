<template>
  <div ref="spPopup" class="popup-wrap" :class="{ 'bg-transparent': transparent }" @click.self="close('outside')">
    <div class="popup-container" :class="{ action: !hasSlotData }" ref="popup">
      <div v-show="title" class="popup-header" :class="customHeaderClass">
        <template v-if="theme !== 'none'" class="icon">
          <component :is="IconComponent" :color="COLOR_DATA[theme]" :size="48" class="mgr-2" />
        </template>
        <h3 class="popup-title">
          <span>{{ title }}</span>
        </h3>
        <slot name="portal"></slot>
      </div>
      <div class="popup-content" :style="{ width: width}" :class="contentsAlign">
        <div v-show="description" class="description" v-html="description.trim()"></div>
        <slot></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SpPopup',
  data() {
    return {
      COLOR_DATA: {
        info: '#CCCCCC',
        success: '#47C944',
        caution: '#E94D4D',
        warning: '#FFD600',
        error: '#E94D4D',
      },
    }
  },
  props: {
    width: {
      type: String,
      default: 'auto',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'center',
    },
    theme: {
      type: String,
      default: 'none',
      validator: (value) => {
        return ['info', 'success', 'warning', 'caution', 'error', 'none'].includes(value)
      },
    },
  },
  computed: {
    contentsAlign() {
      return 'text-' + this.align
    },
    customHeaderClass() {
      if (this.hasSlotData) {
        return 'mgb-32'
      }
      return 'mgb-8'
    },
    IconComponent() {
      const capitalizeFirstLetter = this.theme.charAt(0).toUpperCase() + this.theme.slice(1)
      if (this.theme === 'error') {
        return 'IconNotice'
      }
      if (this.theme === 'caution') {
        return 'IconWarning'
      }
      return `Icon${capitalizeFirstLetter}`
    },
    hasSlotData() {
      if (!Array.isArray(this.$slots.default)) {
        return false
      }
      const childrenElement = this.$slots.default[0].children || []
      return !!this.$slots.default[0].text || childrenElement.length > 0 || this.$slots.default.length > 0
    },
  },
  methods: {
    close(from) {
      if (from === 'outside' && this.closeOnOutsideClick) {
        this.$emit('close')
      }
    },
  },
  created() {
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    const popupList = document.querySelectorAll('.popup-wrap')
    if (popupList.length > 0) {
      return
    }
    document.body.style.overflow = ''
  },
  mounted() {
    const hasButtonEl = this.$refs.popup.querySelector('button:last-child')
    if (!hasButtonEl) {
      return
    }
    hasButtonEl.focus()
  },
}
</script>
<style lang="scss" scoped>
$popup-gap: 32px;
$button-height: 42px;
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &:not(.bg-transparent) {
    @extend .bg-dimmed;
  }
  .popup-header {
    display: block;
    //display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .popup-container {
    min-width: 216px;
    width: fit-content;
    height: fit-content;
    max-width: calc(100vw - 48px);
    max-height: calc(100vh - 32px);
    padding: $popup-gap;
    background-color: $theme-white;
    border-radius: 8px;
    &.action {
      border-radius: 24px;
    }
    .popup-title {
      display: block;
      align-items: center !important;
      text-align: center;
      margin-bottom: 10px;
    }
    .popup-content {
      // overflow: auto;
      margin: 0 0 24px 0;
      max-width: calc(100vw - ($popup-gap * 2) - 48px);
      max-height: calc(100vh - ($popup-gap * 2) - ($button-height * 2) - (48px * 2));
      word-wrap: break-word;
      text-align: center;
    }
    .description {
      @extend .body1;
      color: $theme-gray-50;
      white-space: pre-line;

      ::v-deep .ul-info {
        line-height:18px;

        li {
          font-weight: 380;
          list-style: outside;
        }

        .content-footer{
          color: #000 !important;
        }
      }
    }
  }
}
</style>
