<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5002 7.50568L25.9804 23.8248H7.01989L16.5002 7.50568ZM4.84182 22.5704C3.87239 24.2387 5.08103 26.3335 7.01989 26.3335H25.9804C27.9193 26.3335 29.1279 24.2387 28.1585 22.5704L18.6782 6.25133C17.7088 4.58305 15.2915 4.58305 14.3221 6.25133L4.84182 22.5704ZM15.2412 13.79V16.2987C15.2412 16.9886 15.8077 17.553 16.5002 17.553C17.1926 17.553 17.7592 16.9886 17.7592 16.2987V13.79C17.7592 13.1001 17.1926 12.5356 16.5002 12.5356C15.8077 12.5356 15.2412 13.1001 15.2412 13.79ZM15.2412 21.3161C15.2412 20.6233 15.8074 20.0617 16.5002 20.0617C17.1929 20.0617 17.7592 20.6233 17.7592 21.3161C17.7592 22.0088 17.1929 22.5704 16.5002 22.5704C15.8074 22.5704 15.2412 22.0088 15.2412 21.3161Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconWarning',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
