var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"spModalGroup"}},_vm._l((_vm.modals),function(modal,i){return _c('sp-popup',{key:i,attrs:{"title":modal.title,"theme":modal.theme,"align":modal.align,"description":modal.message.trim(),"closeOnClickOutside":false},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('sp-button-group',[(modal.actionCode === 'ALERT')?_c('sp-button',{on:{"click":function($event){return _vm.onEmit(i, { emit: 'alert', value: undefined })}}},[_vm._v(" 확인 ")]):_vm._e(),(modal.actionCode === 'LOGIN')?_c('sp-button',{on:{"click":() => {
              _vm.modals.splice(i)
              _vm.$router.push('/login').catch((e) => {})
            }}},[_vm._v("확인 ")]):_vm._e(),(modal.actionCode === 'DISTRIBUTION_BATCH')?_c('sp-button',{on:{"click":() => {
              _vm.modals.splice(i)
              _vm.$router.push('/').catch((e) => {})
            }}},[_vm._v("확인 ")]):_vm._e(),(modal.actionCode === 'CONFIRM')?[_c('sp-button',{staticClass:"half-button",attrs:{"outline":""},on:{"click":function($event){return _vm.onEmit(i, { emit: 'confirm', value: false })}}},[_vm._v(_vm._s(modal.cancelName))]),_c('sp-button',{staticClass:"half-button",on:{"click":function($event){return _vm.onEmit(i, { emit: 'confirm', value: true })}}},[_vm._v(_vm._s(modal.confirmName))])]:_vm._e()],2)]},proxy:true}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }