<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4491_15628)">
      <path
        d="M8.00033 3.33268V0.666016L4.66699 3.99935L8.00033 7.33268V4.66602C10.207 4.66602 12.0003 6.45935 12.0003 8.66602C12.0003 10.8727 10.207 12.666 8.00033 12.666C5.79366 12.666 4.00033 10.8727 4.00033 8.66602H2.66699C2.66699 11.6127 5.05366 13.9993 8.00033 13.9993C10.947 13.9993 13.3337 11.6127 13.3337 8.66602C13.3337 5.71935 10.947 3.33268 8.00033 3.33268Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_4491_15628">
        <rect :width="size" :height="size" :fill="color" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconRefresh',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
}
</script>
