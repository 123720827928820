<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.74168 13.2333L10.975 9.99998L7.74168 6.76665C7.41667 6.44165 7.41667 5.91665 7.74167 5.59165C8.06667 5.26665 8.59167 5.26665 8.91667 5.59165L12.7417 9.41665C13.0667 9.74165 13.0667 10.2666 12.7417 10.5916L8.91668 14.4166C8.59168 14.7416 8.06668 14.7416 7.74168 14.4166C7.42501 14.0916 7.41668 13.5583 7.74168 13.2333Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconArrowNext',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
