<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 8C2.5 7.44772 2.94772 7 3.5 7L12.5 7C13.0523 7 13.5 7.44771 13.5 8C13.5 8.55228 13.0523 9 12.5 9L3.5 9C2.94771 9 2.5 8.55229 2.5 8Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconMinus',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
