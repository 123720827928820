<template>
  <sup><span class="sr-only">(필수)</span></sup>
</template>

<script>
export default {
  name: 'IconRequired',
}
</script>
<style lang="scss" scoped>
sup {
  font-size: 0.8rem;
  color: $theme-primary-red;
  line-height: 1;
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '*';
  }
}
</style>
