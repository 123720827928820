<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4926_190219)">
      <path
        d="M19.646 10.38L16 14.026L12.354 10.38C11.808 9.834 10.926 9.834 10.38 10.38C9.834 10.926 9.834 11.808 10.38 12.354L14.026 16L10.38 19.646C9.834 20.192 9.834 21.074 10.38 21.62C10.926 22.166 11.808 22.166 12.354 21.62L16 17.974L19.646 21.62C20.192 22.166 21.074 22.166 21.62 21.62C22.166 21.074 22.166 20.192 21.62 19.646L17.974 16L21.62 12.354C22.166 11.808 22.166 10.926 21.62 10.38C21.074 9.848 20.178 9.848 19.646 10.38ZM16 2C8.258 2 2 8.258 2 16C2 23.742 8.258 30 16 30C23.742 30 30 23.742 30 16C30 8.258 23.742 2 16 2ZM16 27.2C9.826 27.2 4.8 22.174 4.8 16C4.8 9.826 9.826 4.8 16 4.8C22.174 4.8 27.2 9.826 27.2 16C27.2 22.174 22.174 27.2 16 27.2Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_4926_190219">
        <rect width="32" height="32" fill="#808080" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconCancelOutlined',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
