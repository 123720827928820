<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8 23.2H17.2V20.8H14.8V23.2ZM16 4C9.376 4 4 9.376 4 16C4 22.624 9.376 28 16 28C22.624 28 28 22.624 28 16C28 9.376 22.624 4 16 4ZM16 25.6C10.708 25.6 6.4 21.292 6.4 16C6.4 10.708 10.708 6.4 16 6.4C21.292 6.4 25.6 10.708 25.6 16C25.6 21.292 21.292 25.6 16 25.6ZM16 8.8C13.348 8.8 11.2 10.948 11.2 13.6H13.6C13.6 12.28 14.68 11.2 16 11.2C17.32 11.2 18.4 12.28 18.4 13.6C18.4 16 14.8 15.7 14.8 19.6H17.2C17.2 16.9 20.8 16.6 20.8 13.6C20.8 10.948 18.652 8.8 16 8.8Z"
      fill="white"
    />
    <path
      d="M14.8 23.2H17.2V20.8H14.8V23.2ZM16 4C9.376 4 4 9.376 4 16C4 22.624 9.376 28 16 28C22.624 28 28 22.624 28 16C28 9.376 22.624 4 16 4ZM16 25.6C10.708 25.6 6.4 21.292 6.4 16C6.4 10.708 10.708 6.4 16 6.4C21.292 6.4 25.6 10.708 25.6 16C25.6 21.292 21.292 25.6 16 25.6ZM16 8.8C13.348 8.8 11.2 10.948 11.2 13.6H13.6C13.6 12.28 14.68 11.2 16 11.2C17.32 11.2 18.4 12.28 18.4 13.6C18.4 16 14.8 15.7 14.8 19.6H17.2C17.2 16.9 20.8 16.6 20.8 13.6C20.8 10.948 18.652 8.8 16 8.8Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconQuestion',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
