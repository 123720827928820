<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6654 14.0002L11.732 13.0668L12.782 12.0002H9.9987V10.6668H12.782L11.732 9.60016L12.6654 8.66683L15.332 11.3335L12.6654 14.0002ZM2.66536 12.6668C2.2987 12.6668 1.98492 12.5364 1.72403 12.2755C1.4627 12.0142 1.33203 11.7002 1.33203 11.3335V4.66683C1.33203 4.30016 1.4627 3.98638 1.72403 3.7255C1.98492 3.46416 2.2987 3.3335 2.66536 3.3335H11.332C11.6987 3.3335 12.0127 3.46416 12.274 3.7255C12.5349 3.98638 12.6654 4.30016 12.6654 4.66683V7.3335H11.332V5.60016L6.93203 8.66683L2.66536 5.61683V11.3335H8.66536V12.6668H2.66536ZM3.63203 4.66683L6.93203 7.0335L10.332 4.66683H3.63203Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconOutgoing',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
}
</script>
