<template>
  <div class="button-group" :class="customClass">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'SpButtonGroup',
  props: {
    aligns: {
      type: String,
      default: 'center',
      validator: (value) => {
        return ['left', 'center', 'right'].includes(value)
      },
    },
  },
  computed: {
    customClass() {
      const classes = []
      if (this.aligns) {
        classes.push(`sp-align-${this.aligns}`)
      }
      return classes
    },
  },
}
</script>
<style lang="scss" scoped>
.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  &.sp-align-left {
    justify-content: flex-start;
  }
  &.sp-align-center {
    justify-content: center;
  }
  &.sp-align-right {
    justify-content: flex-end;
  }
  .sp-button {
    + .sp-button {
      margin-left: 16px;
    }
  }
}
</style>
