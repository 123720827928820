<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3425_1526)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5277 6.88624C10.5277 8.79602 8.94549 10.3442 6.99371 10.3442C5.04193 10.3442 3.4597 8.79602 3.4597 6.88624C3.4597 4.97647 5.04193 3.42829 6.99371 3.42829C8.94549 3.42829 10.5277 4.97647 10.5277 6.88624ZM9.81715 10.9171C9.01404 11.4567 8.04156 11.7725 6.99371 11.7725C4.23576 11.7725 2 9.58484 2 6.88624C2 4.18765 4.23576 2 6.99371 2C9.75166 2 11.9874 4.18765 11.9874 6.88624C11.9874 8.04405 11.5759 9.1078 10.8881 9.94508L13.8094 12.8035C14.0761 13.0644 14.0612 13.502 13.7762 13.7809C13.4911 14.0598 13.0439 14.0744 12.7772 13.8134L9.81715 10.9171Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3425_1526">
        <rect :width="size" :height="size" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconSearch',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
