import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'

import IconSuccess from '@/components/icons/IconSuccess.vue'
import IconWarning from '@/components/icons/IconWarning.vue'
import IconQuestion from '@/components/icons/IconQuestion.vue'
import IconInfo from '@/components/icons/IconInfo.vue'
import IconPlus from '@/components/icons/IconPlus.vue'
import IconMinus from '@/components/icons/IconMinus.vue'
import IconExit from '@/components/icons/IconExit.vue'
import IconNotice from '@/components/icons/IconNotice.vue'
import IconEyeOn from '@/components/icons/IconEyeOn.vue'
import IconEyeOff from '@/components/icons/IconEyeOff.vue'
import IconArrowFirst from '@/components/icons/IconArrowFirst.vue'
import IconArrowLast from '@/components/icons/IconArrowLast.vue'
import IconArrowPrev from '@/components/icons/IconArrowPrev.vue'
import IconArrowNext from '@/components/icons/IconArrowNext.vue'
import IconFold from '@/components/icons/IconFold.vue'
import IconAttachFile from '@/components/icons/IconAttachFile.vue'
import IconMove from '@/components/icons/IconMove.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconRequired from '@/components/icons/IconRequired.vue'
import IconDownload from '@/components/icons/IconDownload.vue'
import IconImportData from '@/components/icons/IconImportData.vue'
import IconRefresh from '@/components/icons/IconRefresh.vue'
import IconAutoRenew from '@/components/icons/IconAutoRenew.vue'
import IconSpinner from '@/components/icons/IconSpinner.vue'
import IconArrowUp from '@/components/icons/IconArrowUp.vue'
import IconArrowDown from '@/components/icons/IconArrowDown.vue'
import IconPersonAdd from '@/components/icons/IconPersonAdd.vue'
import IconSubDirectory from '@/components/icons/IconSubDirectory.vue'
import IconCancelOutlined from '@/components/icons/IconCancelOutlined.vue'
import IconOutgoing from '@/components/icons/IconOutgoing.vue'

import isOutside from '@/directive/isOutside'
import validity from '@/directive/Validity'
import Popup from '@/components/common/Popup'
import SpPopup from '@/components/common/SpPopup'
import SpSelect from '@/components/common/input/SpSelect'
import SpButton from '@/components/common/SpButton.vue'
import SpButtonGroup from '@/components/common/SpButtonGroup.vue'

import './mixin/Common'
import './assets/scss/common.scss'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(isOutside)
Vue.use(validity)
Vue.use(PortalVue)
Vue.component('Popup', Popup)
Vue.component('SpPopup', SpPopup)
Vue.component('SpSelect', SpSelect)
Vue.component('SpButton', SpButton)
Vue.component('SpButtonGroup', SpButtonGroup)

Vue.component('IconQuestion', IconQuestion)
Vue.component('IconSuccess', IconSuccess)
Vue.component('IconWarning', IconWarning)
Vue.component('IconInfo', IconInfo)
Vue.component('IconPlus', IconPlus)
Vue.component('IconMinus', IconMinus)
Vue.component('IconExit', IconExit)
Vue.component('IconNotice', IconNotice)
Vue.component('IconEyeOn', IconEyeOn)
Vue.component('IconEyeOff', IconEyeOff)
Vue.component('IconAttachFile', IconAttachFile)
Vue.component('IconArrowFirst', IconArrowFirst)
Vue.component('IconArrowLast', IconArrowLast)
Vue.component('IconArrowPrev', IconArrowPrev)
Vue.component('IconArrowNext', IconArrowNext)
Vue.component('IconFold', IconFold)
Vue.component('IconMove', IconMove)
Vue.component('IconSearch', IconSearch)
Vue.component('IconRequired', IconRequired)
Vue.component('IconDownload', IconDownload)
Vue.component('IconImportData', IconImportData)
Vue.component('IconRefresh', IconRefresh)
Vue.component('IconAutoRenew', IconAutoRenew)
Vue.component('IconSpinner', IconSpinner)
Vue.component('IconArrowUp', IconArrowUp)
Vue.component('IconArrowDown', IconArrowDown)
Vue.component('IconPersonAdd', IconPersonAdd)
Vue.component('IconSubDirectory', IconSubDirectory)
Vue.component('IconCancelOutlined', IconCancelOutlined)
Vue.component('IconOutgoing', IconOutgoing)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: h => h(App),
})

router.onReady(() => {
  v.$mount('#app')
})
