<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2583 6.76668L9.02499 10L12.2583 13.2333C12.5833 13.5583 12.5833 14.0833 12.2583 14.4083C11.9333 14.7333 11.4083 14.7333 11.0833 14.4083L7.25832 10.5834C6.93332 10.2583 6.93332 9.73335 7.25832 9.40835L11.0833 5.58335C11.4083 5.25835 11.9333 5.25835 12.2583 5.58335C12.575 5.90835 12.5833 6.44168 12.2583 6.76668Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconArrowPrev',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
