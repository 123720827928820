<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 23C15.23 23 14.6 22.37 14.6 21.6V16C14.6 15.23 15.23 14.6 16 14.6C16.77 14.6 17.4 15.23 17.4 16V21.6C17.4 22.37 16.77 23 16 23ZM16.014 30C23.742 30 30 23.728 30 16C30 8.272 23.742 2 16.014 2C8.272 2 2 8.272 2 16C2 23.728 8.272 30 16.014 30ZM16 4.8C22.188 4.8 27.2 9.812 27.2 16C27.2 22.188 22.188 27.2 16 27.2C9.812 27.2 4.8 22.188 4.8 16C4.8 9.812 9.812 4.8 16 4.8ZM14.6 10.4C14.6 9.6268 15.2268 9 16 9C16.7732 9 17.4 9.6268 17.4 10.4C17.4 11.1732 16.7732 11.8 16 11.8C15.2268 11.8 14.6 11.1732 14.6 10.4Z"
      fill="white"
    />
    <path
      d="M16 23C15.23 23 14.6 22.37 14.6 21.6V16C14.6 15.23 15.23 14.6 16 14.6C16.77 14.6 17.4 15.23 17.4 16V21.6C17.4 22.37 16.77 23 16 23ZM16.014 30C23.742 30 30 23.728 30 16C30 8.272 23.742 2 16.014 2C8.272 2 2 8.272 2 16C2 23.728 8.272 30 16.014 30ZM16 4.8C22.188 4.8 27.2 9.812 27.2 16C27.2 22.188 22.188 27.2 16 27.2C9.812 27.2 4.8 22.188 4.8 16C4.8 9.812 9.812 4.8 16 4.8ZM14.6 10.4C14.6 9.6268 15.2268 9 16 9C16.7732 9 17.4 9.6268 17.4 10.4C17.4 11.1732 16.7732 11.8 16 11.8C15.2268 11.8 14.6 11.1732 14.6 10.4Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconInfo',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
