<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4098_180247)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.86887 5.26367H20.0925V8.80554C20.0925 10.2377 21.2276 11.5508 22.7918 11.5109H25.7501V26.6269C25.7501 26.6794 25.7308 26.7135 25.7134 26.7317C25.7047 26.7408 25.6974 26.745 25.6932 26.7468C25.69 26.7482 25.6852 26.7499 25.6752 26.7499H9.8687C9.8664 26.75 9.85335 26.7501 9.83263 26.7286C9.81072 26.7059 9.79395 26.6713 9.79395 26.6269V21.3409C9.79395 20.6505 9.2343 20.0909 8.54395 20.0909C7.85359 20.0909 7.29395 20.6505 7.29395 21.3409V26.6269C7.29395 28.0301 8.40818 29.2499 9.86887 29.2499H25.6752C27.1571 29.2499 28.2501 28.0218 28.2501 26.6269V10.2745L28.25 10.2556C28.2486 9.9536 28.1379 9.65579 27.9274 9.42287L22.27 3.162C21.9855 2.84717 21.5661 2.70275 21.1581 2.76367H9.86887C8.40818 2.76367 7.29395 3.98353 7.29395 5.38667V10.6727C7.29395 11.3631 7.85359 11.9227 8.54395 11.9227C9.2343 11.9227 9.79395 11.3631 9.79395 10.6727V5.38667C9.79395 5.34232 9.81072 5.30769 9.83263 5.28499C9.85335 5.26351 9.86657 5.26365 9.86887 5.26367ZM22.5925 8.80554V7.27027L24.1691 9.01092H22.7734C22.7591 9.01092 22.7447 9.01117 22.7303 9.01167C22.711 9.01233 22.6875 9.00828 22.6566 8.97643C22.6222 8.94108 22.5925 8.88176 22.5925 8.80554ZM11.3442 12.1345C11.8791 11.6981 12.6665 11.778 13.1029 12.3129L15.5673 15.3335C15.8186 15.6415 15.9054 16.0461 15.8125 16.4228C15.7764 16.5698 15.7131 16.712 15.622 16.8417C15.597 16.8772 15.5704 16.9113 15.5421 16.9438L13.0843 19.8195C12.6357 20.3443 11.8467 20.4061 11.3219 19.9576C10.7971 19.509 10.7353 18.72 11.1838 18.1952L11.886 17.3737H6C5.30964 17.3737 4.75 16.814 4.75 16.1237C4.75 15.4333 5.30964 14.8737 6 14.8737H11.9657L11.1658 13.8933C10.7294 13.3583 10.8093 12.5709 11.3442 12.1345Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_4098_180247">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconImportData',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
