<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 2C8.272 2 2 8.272 2 16C2 23.728 8.272 30 16 30C23.728 30 30 23.728 30 16C30 8.272 23.728 2 16 2ZM16 27.2C9.826 27.2 4.8 22.174 4.8 16C4.8 9.826 9.826 4.8 16 4.8C22.174 4.8 27.2 9.826 27.2 16C27.2 22.174 22.174 27.2 16 27.2ZM21.432 10.806L13.2 19.038L10.568 16.406C10.022 15.86 9.14 15.86 8.594 16.406C8.048 16.952 8.048 17.834 8.594 18.38L12.22 22.006C12.766 22.552 13.648 22.552 14.194 22.006L23.42 12.78C23.966 12.234 23.966 11.352 23.42 10.806C22.874 10.26 21.978 10.26 21.432 10.806Z"
      fill="white"
    />
    <path
      d="M16 2C8.272 2 2 8.272 2 16C2 23.728 8.272 30 16 30C23.728 30 30 23.728 30 16C30 8.272 23.728 2 16 2ZM16 27.2C9.826 27.2 4.8 22.174 4.8 16C4.8 9.826 9.826 4.8 16 4.8C22.174 4.8 27.2 9.826 27.2 16C27.2 22.174 22.174 27.2 16 27.2ZM21.432 10.806L13.2 19.038L10.568 16.406C10.022 15.86 9.14 15.86 8.594 16.406C8.048 16.952 8.048 17.834 8.594 18.38L12.22 22.006C12.766 22.552 13.648 22.552 14.194 22.006L23.42 12.78C23.966 12.234 23.966 11.352 23.42 10.806C22.874 10.26 21.978 10.26 21.432 10.806Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconSuccess',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
