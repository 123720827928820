<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8253 7.5H12.5003V3.33333C12.5003 2.875 12.1253 2.5 11.667 2.5H8.33366C7.87533 2.5 7.50033 2.875 7.50033 3.33333V7.5H6.17533C5.43366 7.5 5.05866 8.4 5.58366 8.925L9.40866 12.75C9.73366 13.075 10.2587 13.075 10.5837 12.75L14.4087 8.925C14.9337 8.4 14.567 7.5 13.8253 7.5ZM4.16699 15.8333C4.16699 16.2917 4.54199 16.6667 5.00033 16.6667H15.0003C15.4587 16.6667 15.8337 16.2917 15.8337 15.8333C15.8337 15.375 15.4587 15 15.0003 15H5.00033C4.54199 15 4.16699 15.375 4.16699 15.8333Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconArrowFirst',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
