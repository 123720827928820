<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6554_42271)">
      <path
        d="M12.666 9.99935L8.66602 13.9993L7.71935 13.0527L10.1127 10.666H2.66602V2.66602H3.99935V9.33268H10.1127L7.71935 6.94602L8.66602 5.99935L12.666 9.99935Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_6554_42271">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconSubDirectory',
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
