<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.997 18.779L6.92576 9.51097C6.60368 9.18312 6.16722 8.99932 5.7124 9C5.25757 9.00068 4.82165 9.18579 4.50051 9.5146C4.17937 9.84341 3.99933 10.289 4 10.7533C4.00067 11.2177 4.18198 11.6627 4.50406 11.9905L14.778 22.4906C15.0899 22.8078 15.5097 22.9901 15.9499 22.9996C16.3901 23.0091 16.817 22.845 17.1418 22.5414L27.4959 11.9978C27.818 11.6699 27.9993 11.2249 28 10.7606C28.0007 10.2963 27.8206 9.85067 27.4995 9.52186C27.1784 9.19305 26.7424 9.00794 26.2876 9.00726C25.8328 9.00658 25.3963 9.19038 25.0742 9.51823L15.997 18.779Z"
      fill="white"
    />
    <path
      d="M15.997 18.779L6.92576 9.51097C6.60368 9.18312 6.16722 8.99932 5.7124 9C5.25757 9.00068 4.82165 9.18579 4.50051 9.5146C4.17937 9.84341 3.99933 10.289 4 10.7533C4.00067 11.2177 4.18198 11.6627 4.50406 11.9905L14.778 22.4906C15.0899 22.8078 15.5097 22.9901 15.9499 22.9996C16.3901 23.0091 16.817 22.845 17.1418 22.5414L27.4959 11.9978C27.818 11.6699 27.9993 11.2249 28 10.7606C28.0007 10.2963 27.8206 9.85067 27.4995 9.52186C27.1784 9.19305 26.7424 9.00794 26.2876 9.00726C25.8328 9.00658 25.3963 9.19038 25.0742 9.51823L15.997 18.779Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconArrowDown',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
