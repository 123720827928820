export default {
  namespaced: true,
  state: {
    seq: null,
  },
  getters: {
    seq: (state) => {
      return state.seq
    },
  },
  mutations: {
    setSeq (state, seq) {
      state.seq = seq || null
    },
  },
  actions: {
    async getVisitorQr ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}checkin/guest/${payload.seq}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getVisitorTermsCheck ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}checkin/guest/${payload.seq}/terms`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postVisitorTermsAgree ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}checkin/guest/${payload.seq}/terms`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
