<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.015 11.9977L20.635 5.19432C20.8692 4.95276 21.0005 4.62542 21 4.2843C20.9995 3.94318 20.8673 3.61623 20.6324 3.37538C20.3976 3.13453 20.0793 2.9995 19.7476 3C19.416 3.0005 19.0981 3.13649 18.8639 3.37805L11.3639 11.0835C11.1373 11.3175 11.007 11.6323 11.0003 11.9624C10.9935 12.2926 11.1107 12.6128 11.3276 12.8563L18.8587 20.622C19.0929 20.8635 19.4108 20.9995 19.7424 21C20.0741 21.0005 20.3924 20.8655 20.6272 20.6246C20.8621 20.3838 20.9943 20.0568 20.9948 19.7157C20.9953 19.3746 20.864 19.0472 20.6298 18.8057L14.015 11.9977Z"
      :fill="color"
    />
    <path
      d="M6.01499 11.9977L12.635 5.19432C12.8692 4.95276 13.0005 4.62542 13 4.2843C12.9995 3.94318 12.8673 3.61623 12.6324 3.37538C12.3976 3.13453 12.0793 2.9995 11.7476 3C11.416 3.0005 11.0981 3.13649 10.8639 3.37805L3.36386 11.0835C3.13725 11.3175 3.00704 11.6323 3.00028 11.9624C2.99352 12.2926 3.11073 12.6128 3.32756 12.8563L10.8587 20.622C11.0929 20.8635 11.4108 20.9995 11.7424 21C12.0741 21.0005 12.3924 20.8655 12.6272 20.6246C12.8621 20.3838 12.9943 20.0568 12.9948 19.7157C12.9953 19.3746 12.864 19.0472 12.6298 18.8057L6.01499 11.9977Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconFold',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: '#FFFFFF',
    },
  },
}
</script>
