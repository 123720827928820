<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5748 4.43441C15.2298 4.08932 14.6723 4.08932 14.3272 4.43441L10.0003 8.75245L5.67344 4.42556C5.32835 4.08048 4.7709 4.08048 4.42581 4.42556C4.08072 4.77065 4.08072 5.32811 4.42581 5.6732L8.7527 10.0001L4.42581 14.327C4.08072 14.6721 4.08072 15.2295 4.42581 15.5746C4.7709 15.9197 5.32835 15.9197 5.67344 15.5746L10.0003 11.2477L14.3272 15.5746C14.6723 15.9197 15.2298 15.9197 15.5748 15.5746C15.9199 15.2295 15.9199 14.6721 15.5748 14.327L11.248 10.0001L15.5748 5.6732C15.9111 5.33695 15.9111 4.77065 15.5748 4.43441Z"
      fill="white"
    />
    <path
      d="M15.5748 4.43441C15.2298 4.08932 14.6723 4.08932 14.3272 4.43441L10.0003 8.75245L5.67344 4.42556C5.32835 4.08048 4.7709 4.08048 4.42581 4.42556C4.08072 4.77065 4.08072 5.32811 4.42581 5.6732L8.7527 10.0001L4.42581 14.327C4.08072 14.6721 4.08072 15.2295 4.42581 15.5746C4.7709 15.9197 5.32835 15.9197 5.67344 15.5746L10.0003 11.2477L14.3272 15.5746C14.6723 15.9197 15.2298 15.9197 15.5748 15.5746C15.9199 15.2295 15.9199 14.6721 15.5748 14.327L11.248 10.0001L15.5748 5.6732C15.9111 5.33695 15.9111 4.77065 15.5748 4.43441Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconExit',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    color: {
      type: String,
      default: '#808080',
    },
  },
}
</script>
