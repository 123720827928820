<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1818 8C11.7891 8 13.0909 6.76937 13.0909 5.25C13.0909 3.73062 11.7891 2.5 10.1818 2.5C8.57455 2.5 7.27273 3.73062 7.27273 5.25C7.27273 6.76937 8.57455 8 10.1818 8ZM10.1818 3.875C10.9818 3.875 11.6364 4.49375 11.6364 5.25C11.6364 6.00625 10.9818 6.625 10.1818 6.625C9.38182 6.625 8.72727 6.00625 8.72727 5.25C8.72727 4.49375 9.38182 3.875 10.1818 3.875ZM10.1818 9.375C8.24 9.375 4.36364 10.2962 4.36364 12.125C4.36364 12.8844 4.97925 13.5 5.73864 13.5H14.625C15.3844 13.5 16 12.8844 16 12.125C16 10.2962 12.1236 9.375 10.1818 9.375ZM5.81818 12.125C5.97818 11.63 8.22545 10.75 10.1818 10.75C12.1455 10.75 14.4 11.6369 14.5455 12.125H5.81818ZM2.90909 10.0625C3.31075 10.0625 3.63636 9.73689 3.63636 9.33523V8H5.13068C5.51038 8 5.81818 7.6922 5.81818 7.3125C5.81818 6.9328 5.51038 6.625 5.13068 6.625H3.63636V5.28977C3.63636 4.88811 3.31075 4.5625 2.90909 4.5625C2.50743 4.5625 2.18182 4.88811 2.18182 5.28977V6.625H0.6875C0.307804 6.625 0 6.9328 0 7.3125C0 7.6922 0.307804 8 0.6875 8H2.18182V9.33523C2.18182 9.73689 2.50743 10.0625 2.90909 10.0625Z"
      fill="white"
    />
    <path
      d="M10.1818 8C11.7891 8 13.0909 6.76937 13.0909 5.25C13.0909 3.73062 11.7891 2.5 10.1818 2.5C8.57455 2.5 7.27273 3.73062 7.27273 5.25C7.27273 6.76937 8.57455 8 10.1818 8ZM10.1818 3.875C10.9818 3.875 11.6364 4.49375 11.6364 5.25C11.6364 6.00625 10.9818 6.625 10.1818 6.625C9.38182 6.625 8.72727 6.00625 8.72727 5.25C8.72727 4.49375 9.38182 3.875 10.1818 3.875ZM10.1818 9.375C8.24 9.375 4.36364 10.2962 4.36364 12.125C4.36364 12.8844 4.97925 13.5 5.73864 13.5H14.625C15.3844 13.5 16 12.8844 16 12.125C16 10.2962 12.1236 9.375 10.1818 9.375ZM5.81818 12.125C5.97818 11.63 8.22545 10.75 10.1818 10.75C12.1455 10.75 14.4 11.6369 14.5455 12.125H5.81818ZM2.90909 10.0625C3.31075 10.0625 3.63636 9.73689 3.63636 9.33523V8H5.13068C5.51038 8 5.81818 7.6922 5.81818 7.3125C5.81818 6.9328 5.51038 6.625 5.13068 6.625H3.63636V5.28977C3.63636 4.88811 3.31075 4.5625 2.90909 4.5625C2.50743 4.5625 2.18182 4.88811 2.18182 5.28977V6.625H0.6875C0.307804 6.625 0 6.9328 0 7.3125C0 7.6922 0.307804 8 0.6875 8H2.18182V9.33523C2.18182 9.73689 2.50743 10.0625 2.90909 10.0625Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconPersonAdd',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
